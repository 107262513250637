import { createGlobalStyle } from 'styled-components'

import 'font-awesome/css/font-awesome.css'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700&display=swap');

  :root {
    --color-scale-black: #010409;
    --color-scale-white: #f0f6fc;
    --color-scale-gray-0: #f0f6fc;
    --color-scale-gray-1: #c9d1d9;
    --color-scale-gray-2: #b1bac4;
    --color-scale-gray-3: #8b949e;
    --color-scale-gray-4: #6e7681;
    --color-scale-gray-5: #484f58;
    --color-scale-gray-6: #30363d;
    --color-scale-gray-7: #21262d;
    --color-scale-gray-8: #151515;
    --color-scale-gray-9: #0a0a0a;
    --color-scale-blue-0: #cae8ff;
    --color-scale-blue-1: #a5d6ff;
    --color-scale-blue-2: #79c0ff;
    --color-scale-blue-3: #58a6ff;
    --color-scale-blue-4: #388bfd;
    --color-scale-blue-5: #1f6feb;
    --color-scale-blue-6: #1158c7;
    --color-scale-blue-7: #0d419d;
    --color-scale-blue-8: #0c2d6b;
    --color-scale-blue-9: #051d4d;
    --color-scale-green-0: #aff5b4;
    --color-scale-green-1: #7ee787;
    --color-scale-green-2: #56d364;
    --color-scale-green-3: #3fb950;
    --color-scale-green-4: #2ea043;
    --color-scale-green-5: #238636;
    --color-scale-green-6: #196c2e;
    --color-scale-green-7: #0f5323;
    --color-scale-green-8: #033a16;
    --color-scale-green-9: #04260f;
    --color-scale-yellow-0: #f8e3a1;
    --color-scale-yellow-1: #f2cc60;
    --color-scale-yellow-2: #e3b341;
    --color-scale-yellow-3: #d29922;
    --color-scale-yellow-4: #bb8009;
    --color-scale-yellow-5: #9e6a03;
    --color-scale-yellow-6: #845306;
    --color-scale-yellow-7: #693e00;
    --color-scale-yellow-8: #4b2900;
    --color-scale-yellow-9: #341a00;
    --color-scale-orange-0: #ffdfb6;
    --color-scale-orange-1: #ffc680;
    --color-scale-orange-2: #ffa657;
    --color-scale-orange-3: #f0883e;
    --color-scale-orange-4: #db6d28;
    --color-scale-orange-5: #bd561d;
    --color-scale-orange-6: #9b4215;
    --color-scale-orange-7: #762d0a;
    --color-scale-orange-8: #5a1e02;
    --color-scale-orange-9: #3d1300;
    --color-scale-red-0: #ffdcd7;
    --color-scale-red-1: #ffc1ba;
    --color-scale-red-2: #ffa198;
    --color-scale-red-3: #ff7b72;
    --color-scale-red-4: #f85149;
    --color-scale-red-5: #da3633;
    --color-scale-red-6: #b62324;
    --color-scale-red-7: #8e1519;
    --color-scale-red-8: #67060c;
    --color-scale-red-9: #490202;
    --color-scale-purple-0: #eddeff;
    --color-scale-purple-1: #e2c5ff;
    --color-scale-purple-2: #d2a8ff;
    --color-scale-purple-3: #bc8cff;
    --color-scale-purple-4: #a371f7;
    --color-scale-purple-5: #8957e5;
    --color-scale-purple-6: #6e40c9;
    --color-scale-purple-7: #553098;
    --color-scale-purple-8: #3c1e70;
    --color-scale-purple-9: #271052;
    --color-scale-pink-0: #ffdaec;
    --color-scale-pink-1: #ffbedd;
    --color-scale-pink-2: #ff9bce;
    --color-scale-pink-3: #f778ba;
    --color-scale-pink-4: #db61a2;
    --color-scale-pink-5: #bf4b8a;
    --color-scale-pink-6: #9e3670;
    --color-scale-pink-7: #7d2457;
    --color-scale-pink-8: #5e103e;
    --color-scale-pink-9: #42062a;
    --color-auto-black: #f0f6fc;
    --color-auto-white: #010409;
    --color-auto-gray-0: #0a0a0a;
    --color-auto-gray-1: #151515;
    --color-auto-gray-2: #21262d;
    --color-auto-gray-3: #30363d;
    --color-auto-gray-4: #484f58;
    --color-auto-gray-5: #6e7681;
    --color-auto-gray-6: #8b949e;
    --color-auto-gray-7: #b1bac4;
    --color-auto-gray-8: #c9d1d9;
    --color-auto-gray-9: #f0f6fc;
    --color-auto-blue-0: #051d4d;
    --color-auto-blue-1: #0c2d6b;
    --color-auto-blue-2: #0d419d;
    --color-auto-blue-3: #1158c7;
    --color-auto-blue-4: #1f6feb;
    --color-auto-blue-5: #388bfd;
    --color-auto-blue-6: #58a6ff;
    --color-auto-blue-7: #79c0ff;
    --color-auto-blue-8: #a5d6ff;
    --color-auto-blue-9: #cae8ff;
    --color-auto-green-0: #04260f;
    --color-auto-green-1: #033a16;
    --color-auto-green-2: #0f5323;
    --color-auto-green-3: #196c2e;
    --color-auto-green-4: #238636;
    --color-auto-green-5: #2ea043;
    --color-auto-green-6: #3fb950;
    --color-auto-green-7: #56d364;
    --color-auto-green-8: #7ee787;
    --color-auto-green-9: #aff5b4;
    --color-auto-yellow-0: #341a00;
    --color-auto-yellow-1: #4b2900;
    --color-auto-yellow-2: #693e00;
    --color-auto-yellow-3: #845306;
    --color-auto-yellow-4: #9e6a03;
    --color-auto-yellow-5: #bb8009;
    --color-auto-yellow-6: #d29922;
    --color-auto-yellow-7: #e3b341;
    --color-auto-yellow-8: #f2cc60;
    --color-auto-yellow-9: #f8e3a1;
    --color-auto-orange-0: #3d1300;
    --color-auto-orange-1: #5a1e02;
    --color-auto-orange-2: #762d0a;
    --color-auto-orange-3: #9b4215;
    --color-auto-orange-4: #bd561d;
    --color-auto-orange-5: #db6d28;
    --color-auto-orange-6: #f0883e;
    --color-auto-orange-7: #ffa657;
    --color-auto-orange-8: #ffc680;
    --color-auto-orange-9: #ffdfb6;
    --color-auto-red-0: #490202;
    --color-auto-red-1: #67060c;
    --color-auto-red-2: #8e1519;
    --color-auto-red-3: #b62324;
    --color-auto-red-4: #da3633;
    --color-auto-red-5: #f85149;
    --color-auto-red-6: #ff7b72;
    --color-auto-red-7: #ffa198;
    --color-auto-red-8: #ffc1ba;
    --color-auto-red-9: #ffdcd7;
    --color-auto-purple-0: #271052;
    --color-auto-purple-1: #3c1e70;
    --color-auto-purple-2: #553098;
    --color-auto-purple-3: #6e40c9;
    --color-auto-purple-4: #8957e5;
    --color-auto-purple-5: #a371f7;
    --color-auto-purple-6: #bc8cff;
    --color-auto-purple-7: #d2a8ff;
    --color-auto-purple-8: #e2c5ff;
    --color-auto-purple-9: #eddeff;
    --color-auto-pink-0: #42062a;
    --color-auto-pink-1: #5e103e;
    --color-auto-pink-2: #7d2457;
    --color-auto-pink-3: #9e3670;
    --color-auto-pink-4: #bf4b8a;
    --color-auto-pink-5: #db61a2;
    --color-auto-pink-6: #f778ba;
    --color-auto-pink-7: #ff9bce;
    --color-auto-pink-8: #ffbedd;
    --color-auto-pink-9: #ffdaec;
    --color-text-primary: #c9d1d9;
    --color-text-secondary: #8b949e;
    --color-text-tertiary: #8b949e;
    --color-text-placeholder: #484f58;
    --color-text-disabled: #484f58;
    --color-text-inverse: #0a0a0a;
    --color-text-link: #58a6ff;
    --color-text-danger: #f85149;
    --color-text-success: #56d364;
    --color-text-warning: #e3b341;
    --color-text-white: #f0f6fc;
    --color-icon-primary: #c9d1d9;
    --color-icon-secondary: #6e7681;
    --color-icon-tertiary: #484f58;
    --color-icon-info: #58a6ff;
    --color-icon-danger: #f85149;
    --color-icon-success: #56d364;
    --color-icon-warning: #e3b341;
    --color-border-primary: #30363d;
    --color-border-secondary: #21262d;
    --color-border-tertiary: #6e7681;
    --color-border-overlay: #30363d;
    --color-border-inverse: #f0f6fc;
    --color-border-info: rgba(56,139,253,0.4);
    --color-border-danger: rgba(248,81,73,0.4);
    --color-border-success: rgba(63,185,80,0.4);
    --color-border-warning: rgba(187,128,9,0.4);
    --color-bg-canvas: #0a0a0a;
    --color-bg-canvas-mobile: #010409;
    --color-bg-canvas-inverse: #f0f6fc;
    --color-bg-canvas-inset: #0a0a0a;
    --color-bg-primary: #0a0a0a;  /* #0a0a0a; */
    --color-bg-secondary: #000000;
    --color-bg-tertiary: #151515;
    --color-bg-overlay: #1c2128;
    --color-bg-backdrop: rgba(1,4,9,0.8);
    --color-bg-info: rgba(56,139,253,0.1);
    --color-bg-info-inverse: #388bfd;
    --color-bg-danger: rgba(248,81,73,0.1);
    --color-bg-danger-inverse: #da3633;
    --color-bg-success: rgba(46,160,67,0.1);
    --color-bg-success-inverse: #2ea043;
    --color-bg-warning: rgba(187,128,9,0.1);
    --color-bg-warning-inverse: #bb8009;
    --color-shadow-small: 0 0 transparent;
    --color-shadow-medium: 0 3px 6px #010409;
    --color-shadow-large: 0 8px 24px #010409;
    --color-shadow-extra-large: 0 12px 48px #010409;
    --color-shadow-highlight: 0 0 transparent;
    --color-shadow-inset: 0 0 transparent;
    --color-state-hover-primary-bg: #1f6feb;
    --color-state-hover-primary-border: #388bfd;
    --color-state-hover-primary-text: #f0f6fc;
    --color-state-hover-primary-icon: #f0f6fc;
    --color-state-hover-secondary-bg: #151515;
    --color-state-hover-secondary-border: #151515;
    --color-state-selected-primary-bg: #1f6feb;
    --color-state-selected-primary-border: #388bfd;
    --color-state-selected-primary-text: #f0f6fc;
    --color-state-selected-primary-icon: #f0f6fc;
    --color-state-focus-border: #388bfd;
    --color-state-focus-shadow: 0 0 0 3px #0c2d6b;
    --color-fade-fg-10: rgba(240,246,252,0.1);
    --color-fade-fg-15: rgba(240,246,252,0.15);
    --color-fade-fg-30: rgba(240,246,252,0.3);
    --color-fade-fg-50: rgba(240,246,252,0.5);
    --color-fade-fg-70: rgba(240,246,252,0.7);
    --color-fade-fg-85: rgba(240,246,252,0.85);
    --color-fade-black-10: rgba(1,4,9,0.1);
    --color-fade-black-15: rgba(1,4,9,0.15);
    --color-fade-black-30: rgba(1,4,9,0.3);
    --color-fade-black-50: rgba(1,4,9,0.5);
    --color-fade-black-70: rgba(1,4,9,0.7);
    --color-fade-black-85: rgba(1,4,9,0.85);
    --color-fade-white-10: rgba(240,246,252,0.1);
    --color-fade-white-15: rgba(240,246,252,0.15);
    --color-fade-white-30: rgba(240,246,252,0.3);
    --color-fade-white-50: rgba(240,246,252,0.5);
    --color-fade-white-70: rgba(240,246,252,0.7);
    --color-fade-white-85: rgba(240,246,252,0.85);
    --color-alert-info-text: #79c0ff;
    --color-alert-info-icon: #79c0ff;
    --color-alert-info-bg: rgba(56,139,253,0.1);
    --color-alert-info-border: rgba(56,139,253,0.4);
    --color-alert-warn-text: #e3b341;
    --color-alert-warn-icon: #e3b341;
    --color-alert-warn-bg: rgba(187,128,9,0.1);
    --color-alert-warn-border: rgba(187,128,9,0.4);
    --color-alert-error-text: #ff7b72;
    --color-alert-error-icon: #ff7b72;
    --color-alert-error-bg: rgba(248,81,73,0.1);
    --color-alert-error-border: rgba(248,81,73,0.4);
    --color-alert-success-text: #56d364;
    --color-alert-success-icon: #56d364;
    --color-alert-success-bg: rgba(46,160,67,0.1);
    --color-alert-success-border: rgba(46,160,67,0.4);
    --color-autocomplete-shadow: 0 16px 32px rgba(1,4,9,0.85);
    --color-autocomplete-row-border: #30363d;
    --color-blankslate-icon: #535b65;
    --color-btn-text: #c9d1d9;
    --color-btn-bg: #21262d;
    --color-btn-border: #30363d;
    --color-btn-shadow: 0 0 transparent;
    --color-btn-inset-shadow: 0 0 transparent;
    --color-btn-hover-bg: #30363d;
    --color-btn-hover-border: #8b949e;
    --color-btn-active-bg: #282e33;
    --color-btn-active-border: #6e7681;
    --color-btn-selected-bg: #151515;
    --color-btn-focus-bg: #21262d;
    --color-btn-focus-border: #8b949e;
    --color-btn-focus-shadow: 0 0 0 3px rgba(139,148,158,0.3);
    --color-btn-shadow-active: inset 0 0.15em 0.3em rgba(1,4,9,0.15);
    --color-btn-shadow-input-focus: 0 0 0 0.2em rgba(31,111,235,0.3);
    --color-btn-primary-text: #fff;
    --color-btn-primary-bg: #238636;
    --color-btn-primary-border: #2ea043;
    --color-btn-primary-shadow: 0 0 transparent;
    --color-btn-primary-inset-shadow: 0 0 transparent;
    --color-btn-primary-hover-bg: #2ea043;
    --color-btn-primary-hover-border: #3fb950;
    --color-btn-primary-selected-bg: #238636;
    --color-btn-primary-selected-shadow: 0 0 transparent;
    --color-btn-primary-disabled-text: rgba(240,246,252,0.5);
    --color-btn-primary-disabled-bg: rgba(35,134,54,0.6);
    --color-btn-primary-disabled-border: transparent;
    --color-btn-primary-focus-bg: #238636;
    --color-btn-primary-focus-border: #3fb950;
    --color-btn-primary-focus-shadow: 0 0 0 3px rgba(46,164,79,0.4);
    --color-btn-primary-icon: #f0f6fc;
    --color-btn-primary-counter-bg: rgba(240,246,252,0.2);
    --color-btn-outline-text: #58a6ff;
    --color-btn-outline-hover-text: #58a6ff;
    --color-btn-outline-hover-bg: #30363d;
    --color-btn-outline-hover-border: #58a6ff;
    --color-btn-outline-hover-shadow: 0 1px 0 rgba(1,4,9,0.1);
    --color-btn-outline-hover-inset-shadow: inset 0 1px 0 rgba(240,246,252,0.03);
    --color-btn-outline-hover-counter-bg: rgba(240,246,252,0.2);
    --color-btn-outline-selected-text: #f0f6fc;
    --color-btn-outline-selected-bg: #0d419d;
    --color-btn-outline-selected-border: rgba(240,246,252,0.1);
    --color-btn-outline-selected-shadow: 0 0 transparent;
    --color-btn-outline-disabled-text: rgba(88,166,255,0.5);
    --color-btn-outline-disabled-bg: #0a0a0a;
    --color-btn-outline-disabled-counter-bg: rgba(31,111,235,0.05);
    --color-btn-outline-focus-border: #58a6ff;
    --color-btn-outline-focus-shadow: 0 0 0 3px rgba(17,88,199,0.4);
    --color-btn-outline-counter-bg: rgba(31,111,235,0.1);
    --color-btn-danger-text: #f85149;
    --color-btn-danger-hover-text: #fff;
    --color-btn-danger-hover-bg: #da3633;
    --color-btn-danger-hover-border: #f85149;
    --color-btn-danger-hover-shadow: 0 0 transparent;
    --color-btn-danger-hover-inset-shadow: 0 0 transparent;
    --color-btn-danger-hover-counter-bg: hsla(0,0%,100%,0.2);
    --color-btn-danger-selected-text: #fff;
    --color-btn-danger-selected-bg: #b62324;
    --color-btn-danger-selected-border: rgba(240,246,252,0.1);
    --color-btn-danger-selected-shadow: 0 0 transparent;
    --color-btn-danger-disabled-text: rgba(248,81,73,0.5);
    --color-btn-danger-disabled-bg: #0a0a0a;
    --color-btn-danger-disabled-counter-bg: rgba(218,54,51,0.05);
    --color-btn-danger-focus-border: #f85149;
    --color-btn-danger-focus-shadow: 0 0 0 3px rgba(182,35,36,0.4);
    --color-btn-danger-counter-bg: rgba(218,54,51,0.1);
    --color-btn-danger-icon: #f85149;
    --color-btn-danger-hover-icon: #f0f6fc;
    --color-btn-counter-bg: #30363d;
    --color-counter-text: #c9d1d9;
    --color-counter-bg: #30363d;
    --color-counter-primary-text: #c9d1d9;
    --color-counter-primary-bg: #6e7681;
    --color-counter-secondary-text: #8b949e;
    --color-counter-secondary-bg: rgba(139,148,158,0.2);
    --color-dropdown-shadow: 0 16px 32px rgba(1,4,9,0.85);
    --color-label-border: #30363d;
    --color-label-primary-text: #b1bac4;
    --color-label-primary-border: #6e7681;
    --color-label-secondary-text: #8b949e;
    --color-label-secondary-border: #30363d;
    --color-label-info-text: #388bfd;
    --color-label-info-border: rgba(56,139,253,0.4);
    --color-label-success-text: #3fb950;
    --color-label-success-border: rgba(46,160,67,0.4);
    --color-label-warning-text: #e3b341;
    --color-label-warning-border: rgba(242,211,91,0.4);
    --color-label-danger-text: #f85149;
    --color-label-danger-border: rgba(248,81,73,0.4);
    --color-label-orange-text: #db6d28;
    --color-label-orange-border: rgba(219,109,40,0.4);
    --color-input-bg: #0a0a0a;
    --color-input-contrast-bg: rgba(1,4,9,0.5);
    --color-input-border: #21262d;
    --color-input-shadow: 0 0 transparent;
    --color-input-disabled-bg: #151515;
    --color-input-disabled-border: #30363d;
    --color-input-warning-border: #d29922;
    --color-input-error-border: #f85149;
    --color-input-tooltip-success-text: #56d364;
    --color-input-tooltip-success-bg: #101f1b;
    --color-input-tooltip-success-border: #1c532b;
    --color-input-tooltip-warning-text: #e3b341;
    --color-input-tooltip-warning-bg: #1e1c16;
    --color-input-tooltip-warning-border: #5d4411;
    --color-input-tooltip-error-text: #ff7b72;
    --color-input-tooltip-error-bg: #25171c;
    --color-input-tooltip-error-border: #792e2e;
    --color-avatar-bg: rgba(240,246,252,0.1);
    --color-avatar-border: rgba(240,246,252,0.1);
    --color-avatar-stack-fade: #30363d;
    --color-avatar-stack-fade-more: #21262d;
    --color-avatar-child-shadow: -2px -2px 0 #0a0a0a;
    --color-toast-text: #c9d1d9;
    --color-toast-bg: #30363d;
    --color-toast-border: #6e7681;
    --color-toast-shadow: 0 8px 24px #010409;
    --color-toast-icon: #f0f6fc;
    --color-toast-icon-bg: #1f6feb;
    --color-toast-icon-border: #388bfd;
    --color-toast-success-text: #c9d1d9;
    --color-toast-success-border: #6e7681;
    --color-toast-success-icon: #f0f6fc;
    --color-toast-success-icon-bg: #2ea043;
    --color-toast-success-icon-border: #3fb950;
    --color-toast-warning-text: #c9d1d9;
    --color-toast-warning-border: #6e7681;
    --color-toast-warning-icon: #f0f6fc;
    --color-toast-warning-icon-bg: #bb8009;
    --color-toast-warning-icon-border: #d29922;
    --color-toast-danger-text: #c9d1d9;
    --color-toast-danger-border: #6e7681;
    --color-toast-danger-icon: #f0f6fc;
    --color-toast-danger-icon-bg: #da3633;
    --color-toast-danger-icon-border: #f85149;
    --color-toast-loading-text: #c9d1d9;
    --color-toast-loading-border: #6e7681;
    --color-toast-loading-icon: #f0f6fc;
    --color-toast-loading-icon-bg: #6e7681;
    --color-toast-loading-icon-border: #8b949e;
    --color-timeline-text: #b1bac4;
    --color-timeline-badge-bg: #0a0a0a;
    --color-timeline-badge-success-border: #2ea043;
    --color-timeline-target-badge-border: #1f6feb;
    --color-timeline-target-badge-shadow: #0d419d;
    --color-select-menu-border-secondary: #30363d;
    --color-select-menu-shadow: 0 0 18px rgba(1,4,9,0.4);
    --color-select-menu-backdrop-bg: rgba(1,4,9,0.5);
    --color-select-menu-backdrop-border: #484f58;
    --color-select-menu-tap-highlight: rgba(48,54,61,0.5);
    --color-select-menu-tap-focus-bg: #0c2d6b;
    --color-box-blue-border: #0d419d;
    --color-box-row-yellow-bg: rgba(235,196,64,0.1);
    --color-box-row-blue-bg: rgba(121,192,255,0.1);
    --color-box-header-blue-bg: #0a0a0a;
    --color-box-header-blue-border: #30363d;
    --color-box-border-info: rgba(56,139,253,0.4);
    --color-box-bg-info: rgba(56,139,253,0.1);
    --color-box-border-warning: rgba(187,128,9,0.4);
    --color-box-bg-warning: rgba(187,128,9,0.1);
    --color-branch-name-text: #c9d1d9;
    --color-branch-name-icon: #b1bac4;
    --color-branch-name-bg: rgba(88,166,255,0.1);
    --color-branch-name-link-text: #58a6ff;
    --color-branch-name-link-icon: #58a6ff;
    --color-branch-name-link-bg: rgba(88,166,255,0.1);
    --color-markdown-code-bg: rgba(240,246,252,0.15);
    --color-markdown-frame-border: #3a424a;
    --color-markdown-blockquote-border: #3a424a;
    --color-markdown-table-border: #3a424a;
    --color-markdown-table-tr-border: #262b31;
    --color-menu-heading-text: #8b949e;
    --color-menu-border-active: #f78166;
    --color-menu-bg-active: #151515;
    --color-sidenav-selected-bg: #21262d;
    --color-sidenav-border-active: #f78166;
    --color-header-text: rgba(240,246,252,0.7);
    --color-header-bg: #151515;
    --color-header-logo: #f0f6fc;
    --color-filter-item-bar-bg: #12161c;
    --color-hidden-text-expander-bg: #21262d;
    --color-hidden-text-expander-bg-hover: #30363d;
    --color-drag-and-drop-border: #24282e;
    --color-upload-enabled-border: #3a424a;
    --color-upload-enabled-border-focused: #4388ef;
    --color-previewable-comment-form-border: #24282e;
    --color-underlinenav-border: rgba(48,54,61,0);
    --color-underlinenav-border-hover: #30363d;
    --color-underlinenav-border-active: #f78166;
    --color-underlinenav-text: #8b949e;
    --color-underlinenav-text-hover: #c9d1d9;
    --color-underlinenav-text-active: #c9d1d9;
    --color-underlinenav-icon: #6e7681;
    --color-underlinenav-icon-hover: #c9d1d9;
    --color-underlinenav-icon-active: #c9d1d9;
    --color-underlinenav-counter-text: #8b949e;
    --color-underlinenav-counter-bg: rgba(139,148,158,0.2);
    --color-verified-badge-text: #3fb950;
    --color-verified-badge-bg: rgba(63,185,80,0.1);
    --color-verified-badge-border: rgba(63,185,80,0.4);
    --color-social-count-bg: #21262d;
    --color-tooltip-text: #f0f6fc;
    --color-tooltip-bg: #6e7681;
    --color-header-search-bg: #0a0a0a;
    --color-header-search-border: #21262d;
    --color-search-keyword-hl: rgba(187,128,9,0.4);
    --color-diffstat-neutral-bg: #30363d;
    --color-diffstat-neutral-border: rgba(240,246,252,0.1);
    --color-diffstat-deletion-bg: #da3633;
    --color-diffstat-deletion-border: #f85149;
    --color-diffstat-addition-bg: #238636;
    --color-diffstat-addition-border: #2ea043;
    --color-mktg-success: #29933d;
    --color-mktg-info: #2a7bf3;
    --color-mktg-bg-shade-gradient-top: rgba(1,4,9,0.065);
    --color-mktg-bg-shade-gradient-bottom: rgba(1,4,9,0);
    --color-mktg-btn-bg-top: #617eef;
    --color-mktg-btn-bg-bottom: #4969ed;
    --color-mktg-btn-bg-overlay-top: #4968e4;
    --color-mktg-btn-bg-overlay-bottom: #3355e0;
    --color-mktg-btn-text: #f0f6fc;
    --color-mktg-btn-primary-bg-top: #34b759;
    --color-mktg-btn-primary-bg-bottom: #2ea44f;
    --color-mktg-btn-primary-bg-overlay-top: #279b42;
    --color-mktg-btn-primary-bg-overlay-bottom: #22863a;
    --color-mktg-btn-primary-text: #f0f6fc;
    --color-mktg-btn-enterprise-bg-top: #8670ff;
    --color-mktg-btn-enterprise-bg-bottom: #6f57ff;
    --color-mktg-btn-enterprise-bg-overlay-top: #7463de;
    --color-mktg-btn-enterprise-bg-overlay-bottom: #614eda;
    --color-mktg-btn-enterprise-text: #f0f6fc;
    --color-mktg-btn-outline-text: #f0f6fc;
    --color-mktg-btn-outline-border: rgba(240,246,252,0.3);
    --color-mktg-btn-outline-hover-text: #f0f6fc;
    --color-mktg-btn-outline-hover-border: rgba(240,246,252,0.5);
    --color-mktg-btn-outline-focus-border: #f0f6fc;
    --color-mktg-btn-outline-focus-border-inset: rgba(240,246,252,0.5);
    --color-mktg-btn-dark-text: #f0f6fc;
    --color-mktg-btn-dark-border: rgba(240,246,252,0.3);
    --color-mktg-btn-dark-hover-text: #f0f6fc;
    --color-mktg-btn-dark-hover-border: rgba(240,246,252,0.5);
    --color-mktg-btn-dark-focus-border: #f0f6fc;
    --color-mktg-btn-dark-focus-border-inset: rgba(240,246,252,0.5);
    --color-files-explorer-icon: #6e7681;
    --color-hl-author-bg: #051d4d;
    --color-hl-author-border: #0d419d;
    --color-logo-subdued: #30363d;
    --color-discussion-border: #34513b;
    --color-discussion-bg-success: rgba(46,160,67,0.1);
    --color-actions-workflow-table-sticky-bg: rgba(13,17,23,0.95);
    --color-repo-language-color-border: rgba(240,246,252,0.2);
    --color-code-selection-bg: rgba(121,192,255,0.3);
    --color-highlight-text: #ffd467;
    --color-highlight-bg: rgba(204,143,44,0.38);
    --color-blob-line-highlight-bg: rgba(210,153,34,0.15);
    --color-blob-line-highlight-border: #e3b341;
    --color-diff-addition-text: #56d364;
    --color-diff-addition-bg: rgba(46,160,67,0.2);
    --color-diff-addition-border: #196c2e;
    --color-diff-deletion-text: #f85149;
    --color-diff-deletion-bg: rgba(218,54,51,0.2);
    --color-diff-deletion-border: #b62324;
    --color-diff-change-text: #e3b341;
    --color-diff-change-bg: #341a00;
    --color-diff-change-border: #9e6a03;
    --color-diff-blob-num-text: rgba(240,246,252,0.3);
    --color-diff-blob-num-hover-text: rgba(240,246,252,0.6);
    --color-diff-blob-addition-num-text: #3fb950;
    --color-diff-blob-addition-num-hover-text: #7ee787;
    --color-diff-blob-addition-num-bg: rgba(46,160,67,0.1);
    --color-diff-blob-addition-line-bg: rgba(46,160,67,0.2);
    --color-diff-blob-addition-word-bg: rgba(46,160,67,0.55);
    --color-diff-blob-deletion-num-text: #f85149;
    --color-diff-blob-deletion-num-hover-text: #ffa198;
    --color-diff-blob-deletion-num-bg: rgba(218,54,51,0.1);
    --color-diff-blob-deletion-line-bg: rgba(218,54,51,0.2);
    --color-diff-blob-deletion-word-bg: rgba(218,54,51,0.5);
    --color-diff-blob-hunk-text: #8b949e;
    --color-diff-blob-hunk-num-bg: rgba(88,166,255,0.15);
    --color-diff-blob-hunk-line-bg: rgba(88,166,255,0.1);
    --color-diff-blob-empty-block-bg: #151515;
    --color-diff-blob-selected-line-highlight-bg: rgba(187,128,9,0.1);
    --color-diff-blob-selected-line-highlight-border: #bb8009;
    --color-diff-blob-selected-line-highlight-mix-blend-mode: normal;
    --color-diff-blob-expander-icon: #8b949e;
    --color-diff-blob-expander-hover-icon: #f0f6fc;
    --color-diff-blob-expander-hover-bg: #1f6feb;
    --color-diff-blob-comment-button-icon: #f0f6fc;
    --color-diff-blob-comment-button-bg: #1f6feb;
    --color-diff-blob-comment-button-gradient-bg: #357fed;
    --color-global-nav-logo: #f0f6fc;
    --color-global-nav-bg: #151515;
    --color-global-nav-text: #c9d1d9;
    --color-global-nav-icon: #c9d1d9;
    --color-global-nav-input-bg: #0a0a0a;
    --color-global-nav-input-border: #21262d;
    --color-global-nav-input-icon: #21262d;
    --color-global-nav-input-placeholder: #484f58;
    --color-footer-invertocat-octicon: #30363d;
    --color-footer-invertocat-octicon-hover: #6e7681;
    --color-pr-state-draft-text: #8b949e;
    --color-pr-state-draft-bg: rgba(139,148,158,0.1);
    --color-pr-state-draft-border: rgba(139,148,158,0.4);
    --color-pr-state-open-text: #3fb950;
    --color-pr-state-open-bg: rgba(63,185,80,0.1);
    --color-pr-state-open-border: rgba(63,185,80,0.4);
    --color-pr-state-merged-text: #a371f7;
    --color-pr-state-merged-bg: rgba(188,140,255,0.1);
    --color-pr-state-merged-border: rgba(188,140,255,0.4);
    --color-pr-state-closed-text: #f85149;
    --color-pr-state-closed-bg: rgba(218,54,51,0.1);
    --color-pr-state-closed-border: rgba(218,54,51,0.4);
    --color-topic-tag-text: #58a6ff;
    --color-topic-tag-bg: rgba(56,139,253,0.1);
    --color-topic-tag-hover-bg: rgba(56,139,253,0.2);
    --color-topic-tag-active-bg: rgba(56,139,253,0.15);
    --color-merge-box-success-icon-bg: rgba(46,160,67,0.1);
    --color-merge-box-success-icon-text: #3fb950;
    --color-merge-box-success-icon-border: rgba(46,160,67,0.4);
    --color-merge-box-success-indicator-bg: #238636;
    --color-merge-box-success-indicator-border: #2ea043;
    --color-merge-box-merged-icon-bg: rgba(188,140,255,0.1);
    --color-merge-box-merged-icon-text: #a371f7;
    --color-merge-box-merged-icon-border: rgba(188,140,255,0.4);
    --color-merge-box-merged-box-border: rgba(188,140,255,0.4);
    --color-merge-box-neutral-icon-bg: rgba(201,209,217,0.1);
    --color-merge-box-neutral-icon-text: #8b949e;
    --color-merge-box-neutral-icon-border: rgba(201,209,217,0.4);
    --color-merge-box-neutral-indicator-bg: #484f58;
    --color-merge-box-neutral-indicator-border: #6e7681;
    --color-merge-box-warning-icon-bg: rgba(187,128,9,0.1);
    --color-merge-box-warning-icon-text: #e3b341;
    --color-merge-box-warning-icon-border: rgba(187,128,9,0.4);
    --color-merge-box-warning-box-border: rgba(187,128,9,0.4);
    --color-merge-box-warning-merge-highlight: rgba(187,128,9,0.1);
    --color-merge-box-error-icon-bg: rgba(248,81,73,0.1);
    --color-merge-box-error-icon-text: #f85149;
    --color-merge-box-error-icon-border: rgba(248,81,73,0.4);
    --color-merge-box-error-indicator-bg: #da3633;
    --color-merge-box-error-indicator-border: #f85149;
    --color-project-card-bg: #151515;
    --color-project-header-bg: #0a0a0a;
    --color-project-sidebar-bg: #151515;
    --color-project-gradient-in: #151515;
    --color-project-gradient-out: rgba(22,27,34,0);
    --color-checks-bg: #0a0a0a;
    --color-checks-run-border-width: 1px;
    --color-checks-container-border-width: 1px;
    --color-checks-text-primary: #c9d1d9;
    --color-checks-text-secondary: #8b949e;
    --color-checks-text-link: #58a6ff;
    --color-checks-btn-icon: #6e7681;
    --color-checks-btn-hover-icon: #c9d1d9;
    --color-checks-btn-hover-bg: #30363d;
    --color-checks-input-text: #8b949e;
    --color-checks-input-placeholder-text: #484f58;
    --color-checks-input-focus-text: #c9d1d9;
    --color-checks-input-bg: #0a0a0a;
    --color-checks-input-shadow: 0 0 0 1px #21262d;
    --color-checks-dropdown-text: #c9d1d9;
    --color-checks-dropdown-bg: #1c2128;
    --color-checks-dropdown-border: #30363d;
    --color-checks-dropdown-hover-text: #f0f6fc;
    --color-checks-dropdown-hover-bg: #1f6feb;
    --color-checks-dropdown-btn-hover-text: #f0f6fc;
    --color-checks-dropdown-btn-hover-bg: #151515;
    --color-checks-scrollbar-thumb-bg: #30363d;
    --color-checks-header-label-text: #8b949e;
    --color-checks-header-label-open-text: #c9d1d9;
    --color-checks-header-border: #21262d;
    --color-checks-header-icon: #6e7681;
    --color-checks-line-text: #8b949e;
    --color-checks-line-num-text: #8b949e;
    --color-checks-line-timestamp-text: #8b949e;
    --color-checks-line-hover-bg: #151515;
    --color-checks-line-selected-bg: rgba(56,139,253,0.1);
    --color-checks-line-selected-num-text: #58a6ff;
    --color-checks-line-dt-fm-text: #0a0a0a;
    --color-checks-line-dt-fm-bg: #d29922;
    --color-checks-gate-bg: rgba(132,83,6,0.15);
    --color-checks-gate-text: #8b949e;
    --color-checks-gate-waiting-text: #e3b341;
    --color-checks-step-header-open-bg: #151515;
    --color-checks-step-error-text: #f85149;
    --color-checks-step-warning-text: #e3b341;
    --color-checks-logline-text: #6e7681;
    --color-checks-logline-num-text: #8b949e;
    --color-checks-logline-debug-text: #bc8cff;
    --color-checks-logline-error-text: #8b949e;
    --color-checks-logline-error-num-text: #8b949e;
    --color-checks-logline-error-bg: rgba(248,81,73,0.1);
    --color-checks-logline-warning-text: #8b949e;
    --color-checks-logline-warning-num-text: #e3b341;
    --color-checks-logline-warning-bg: rgba(187,128,9,0.1);
    --color-checks-logline-command-text: #58a6ff;
    --color-checks-logline-section-text: #56d364;
    --color-checks-ansi-black: #0a0a0a;
    --color-checks-ansi-black-bright: #151515;
    --color-checks-ansi-white: #b1bac4;
    --color-checks-ansi-white-bright: #b1bac4;
    --color-checks-ansi-gray: #6e7681;
    --color-checks-ansi-red: #ff7b72;
    --color-checks-ansi-red-bright: #ffa198;
    --color-checks-ansi-green: #3fb950;
    --color-checks-ansi-green-bright: #56d364;
    --color-checks-ansi-yellow: #d29922;
    --color-checks-ansi-yellow-bright: #e3b341;
    --color-checks-ansi-blue: #58a6ff;
    --color-checks-ansi-blue-bright: #79c0ff;
    --color-checks-ansi-magenta: #bc8cff;
    --color-checks-ansi-magenta-bright: #d2a8ff;
    --color-checks-ansi-cyan: #76e3ea;
    --color-checks-ansi-cyan-bright: #b3f0ff;
    --color-intro-shelf-gradient-left: rgba(56,139,253,0.1);
    --color-intro-shelf-gradient-right: rgba(46,160,67,0.1);
    --color-intro-shelf-gradient-in: #0a0a0a;
    --color-intro-shelf-gradient-out: rgba(13,17,23,0);
    --color-marketing-icon-primary: #79c0ff;
    --color-marketing-icon-secondary: #1f6feb;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #c9d1d9;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-codemirror-text: #c9d1d9;
    --color-codemirror-bg: #0a0a0a;
    --color-codemirror-gutters-bg: #0a0a0a;
    --color-codemirror-guttermarker-text: #0a0a0a;
    --color-codemirror-guttermarker-subtle-text: #6e7681;
    --color-codemirror-linenumber-text: #8b949e;
    --color-codemirror-cursor: #f0f6fc;
    --color-codemirror-selection-bg: rgba(121,192,255,0.3);
    --color-codemirror-activeline-bg: #151515;
    --color-codemirror-matchingbracket-text: #c9d1d9;
    --color-codemirror-lines-bg: #0a0a0a;
    --color-codemirror-syntax-comment: #8b949e;
    --color-codemirror-syntax-constant: #79c0ff;
    --color-codemirror-syntax-entity: #d2a8ff;
    --color-codemirror-syntax-keyword: #ff7b72;
    --color-codemirror-syntax-storage: #ff7b72;
    --color-codemirror-syntax-string: #a5d6ff;
    --color-codemirror-syntax-support: #79c0ff;
    --color-codemirror-syntax-variable: #ffa657;
    --color-ansi-black: #484f58;
    --color-ansi-black-bright: #6e7681;
    --color-ansi-white: #b1bac4;
    --color-ansi-white-bright: #f0f6fc;
    --color-ansi-gray: #6e7681;
    --color-ansi-red: #ff7b72;
    --color-ansi-red-bright: #ffa198;
    --color-ansi-green: #3fb950;
    --color-ansi-green-bright: #56d364;
    --color-ansi-yellow: #d29922;
    --color-ansi-yellow-bright: #e3b341;
    --color-ansi-blue: #58a6ff;
    --color-ansi-blue-bright: #79c0ff;
    --color-ansi-magenta: #bc8cff;
    --color-ansi-magenta-bright: #d2a8ff;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
    overflow-x: hidden;
  }

  body, html {
    background-color: var(--color-bg-primary);
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    font-size: 1.0em;
    line-height: 1.825;
    color: var(--color-text-white);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
    width: 100%;
  }

  /* ------ WEBKIT SCROLLBAR STYLE ----- */
  ::-webkit-scrollbar {width: 7px;}
  ::-webkit-scrollbar-track {background-color: var(--color-text-white);}
  ::-webkit-scrollbar-thumb {background-color: var(--color-border-primary);}
  ::-webkit-scrollbar-thumb:hover {background-color: var(--color-box-bg-info);}

`

export default GlobalStyle
