import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Logo from '../../assets/logo_192.png'
import api from '../../services/api'
import { login } from '../../services/auth'

import { Form, Container } from './styles'

import Footer from '../../components/Footer'

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    error: '',
  }

  handleSignIn = async (e) => {
    e.preventDefault()
    const { email, password } = this.state
    if (!email || !password) {
      this.setState({ error: 'Preencha e-mail e senha para continuar!' })
    } else {
      try {
        const response = await api.post('/sessions', { email, password })

        let wallet_id = response.data.user_data.wallet_id
        if (wallet_id != process.env.REACT_APP_WALLET_VALIDATE_ID) {
          this.setState({
            error:
              'Você não tem acesso à esta carteira de NFT. Por favor, verifique se a URL de acesso está correta.',
          })
        } else {
          login(
            response.data.token.token,
            response.data.token.refreshToken,
            JSON.stringify(response.data.user_data),
          )
          this.props.history.push('/feed')
        }
      } catch (err) {
        this.setState({
          error: 'Houve um problema com o login, verifique suas credenciais.',
        })
      }
    }
  }

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignIn}>
          <img src={Logo} alt="MyWallet" />
          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <button type="submit">Entrar</button>

          {/* <Link to="/signup">Criar conta grátis</Link> */}
        </Form>

        <Footer />
      </Container>
    )
  }
}

export default withRouter(SignIn)
