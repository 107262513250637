import styled from "styled-components";

export const MainContainer = styled.main`
  @media (min-height: 1080px) and (orientation: portrait) {
    min-height: 85vh;
  }

  @media (max-height: 900px) and (orientation: landscape) {
    min-height: 70vh;
  }

  @media (max-height: 768px) and (orientation: landscape) {
    min-height: 64vh;
  }

  @media (max-height: 740px) and (orientation: portrait) {
    min-height: 72vh;
  }

  min-height: 76vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-top: 1rem;
`;

export const MainContent = styled.div`
  @media (max-width: 740px) and (orientation: portrait) {
    width: fit-content;
    min-width: 260px;
    max-width: 340px;
  }

  width: 90vw;
  padding: 0.05rem;
  margin: 0 auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
`;

export const NftCardWrapper = styled.div``;
