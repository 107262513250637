export const TOKEN = "@mywallet-token";
export const REFRESH_TOKEN = "@mywallet-refreshToken";
export const USER_DATA = "@mywallet-data";
export const isAuthenticated = () => localStorage.getItem(TOKEN) !== null;
export const getToken = () => localStorage.getItem(TOKEN);
export const login = (token, refreshToken, user_data) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  localStorage.setItem(USER_DATA, user_data);
};
export const logout = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(USER_DATA);
};