import React, { useState, useEffect } from "react";

import opensea from "../../services/opensea";

export default function OpenSeaNftImg(props) {

  const [nftImg, setNftImg] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();
    const opts = { signal: abortCtrl.signal };

    const { name, external_link, asset_contract, token_id } = props.nft;
    const nftImgObj = {
      image_url: external_link,
      name
    }

    if (
      typeof asset_contract !== 'undefined' &&
      typeof token_id !== 'undefined' &&
      typeof nftImg.image_url === 'undefined'
    ) {
      opensea.get(`/asset/${asset_contract}/${token_id}`, opts)
        .then(response => {
          setNftImg(response.data)
        })
        .catch(err => {
          console.error(`OpenSea couldn't get data because the token was mint on another chain than Ethereum.`)
        })
    }

    if (typeof nftImg.image_url === 'undefined') {
      setNftImg(nftImgObj)
    }

    return () => abortCtrl.abort()

  }, [props, nftImg])

  return <img src={nftImg.image_url} alt={nftImg.name} />

}

