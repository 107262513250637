import styled from 'styled-components'

export const NftCardContent = styled.div`
  .card {
    @media (max-width: 740px) and (orientation: portrait) {
      position: relative;
      left: -0.6rem;
    }

    position: relative;
    left: 0;

    height: 550px;
    width: 320px;

    background-color: var(--color-project-sidebar-bg);
    border: 1px solid var(--color-border-primary);
    padding: 0;
    margin: 1rem;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: all 0.9s ease-in-out;

    &:hover,
    &:focus {
      border-color: var(--color-auto-yellow-8);
    }
  }

  .nft-info {
    position: relative;
    top: -20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  strong {
    margin-top: 20px;
    font-weight: 600;
    font-size: 1.3em;
    text-align: center;
    overflow: hidden;
  }

  p {
    margin-top: 8px;
    padding: 0 12px;
    font-size: 0.9em;
    width: fit-content;
    min-width: 260px;
    max-width: 300px;
  }

  .token-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .token-id > label,
  .token-qty > label {
    padding: 0 12px;
    color: var(--color-scale-blue-4);
    font-size: 0.9em;
  }

  .token-id > p,
  .token-qty > span {
    font-size: 1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .token-id > p {
    margin-top: -4px;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  height: 300px;
  width: 300px;

  position: relative;
  top: -30px;

  img {
    width: fit-content;
    max-width: 300px;
  }
`

export const NftFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  overflow: hidden;

  width: fit-content;
  min-width: 318px;
  height: 48px;

  background-color: var(--color-bg-canvas-inset);
  border-top: 1px solid var(--color-border-primary);

  position: absolute;
  bottom: 0;

  .badge {
    width: 48px;
    height: 48px;
    background-color: var(--color-state-hover-secondary-bg);
    overflow: hidden;
  }

  .badge img {
    width: 32px;
    height: 32px;
    margin: 10px 0 0 8px;
  }

  .badge.border-left {
    border-left: 1px solid var(--color-border-primary);
  }

  .badge.border-right {
    border-right: 1px solid var(--color-border-primary);
  }

  .ui-icon {
    background-color: var(--color-auto-yellow-8);
    width: 48px;
    height: 48px;
    position: relative;
    top: 2px;
    right: 1px;
    transition: ease 0.4s;
  }

  .ui-icon > a {
    position: relative;
    top: 6px;
    left: 6px;
    transition: ease 0.4s;
    color: var(--color-auto-yellow-5);
  }

  .ui-icon:hover {
    background-color: var(--color-auto-yellow-5);
  }

  .ui-icon > a:hover {
    color: var(--color-auto-yellow-8);
  }
`
