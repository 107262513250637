import React, { useState, useEffect } from 'react'
import ReactCardFlip from 'react-card-flip'

import { NftCardContent, ImgContainer, NftFooter } from './styles'
import OpenSeaNftImg from '../OpenSeaNFTImg'
import QRCodeCanvas from '../QRCodeCanvas'
import OpenSeaSvg from '../../assets/opensea.svg'
import { NavigateNext } from '@material-ui/icons'
import NftTransfer from '../NftTransfer'

export default function NftCard(props) {
  const [flip, setFlip] = useState(false)
  const [nft, setNft] = useState([])

  useEffect(() => {
    setNft(props.nft)
  }, [props])

  return (
    <NftCardContent>
      <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
        {/* Front card */}
        <div className="card">
          <ImgContainer onClick={() => setFlip(!flip)}>
            <OpenSeaNftImg nft={nft} />
          </ImgContainer>

          <div className="nft-info">
            <strong>{nft.name}</strong>
            {/*<p>{nft.description}</p>*/}
          </div>

          <NftTransfer nft={nft} />

          <NftFooter>
            <span className="opensea badge border-right">
              <img src={OpenSeaSvg} alt="Validar NFT na OpenSea." />
            </span>
            <span className="ui-icon badge border-left">
              <a
                href={nft.nft_validate_link}
                target="_blank"
                rel="noreferrer"
                className="btn-navigate-nft"
              >
                <NavigateNext fontSize="large" />
              </a>
            </span>
          </NftFooter>
        </div>

        {/* Back card */}
        <div className="card">
          <div className="canvas-wrapper" onClick={() => setFlip(!flip)}>
            <QRCodeCanvas qrcode_link={nft.qrcode_link} />
          </div>
          <br />
          <div className="token-info">
            <div className="token-id">
              <label>Token ID:</label>
              <p>{nft.token_id}</p>
            </div>
            {nft.token_qty > 1 && (
              <div className="token-qty">
                <label>Quantidade de tokens:</label>
                <span>{nft.token_qty}</span>
              </div>
            )}
          </div>
        </div>
      </ReactCardFlip>
    </NftCardContent>
  )
}
