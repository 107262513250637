import React, { useEffect, useRef } from 'react'
import QRCode from 'qrcode'

export default function QRCodeCanvas({ qrcode_link }) {

  const canvasRef = useRef()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qrCodeOpts = {
    width: 280,
    errorCorrectionLevel: 'L',
    type: 'svg',
    quality: 0.95,
    margin: 1,
    color: {
      dark: '#0D1117',
      light: '#FFF'
    }
  }

  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      qrcode_link,
      qrCodeOpts,
      (err) => {
        // se tirar isso quebra a parada toda
        // err: no input text 
      }
    )
  }, [qrcode_link, qrCodeOpts])

  return (
    <>
      <canvas ref={canvasRef}></canvas>
    </>
  )

}