import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { HeaderContainer, HeaderContent, UserContent } from './styles'

import Logo from '../../assets/logo_96.png'
import { ExitToApp } from '@material-ui/icons'

import { logout } from '../../services/auth'

class Header extends Component {
  state = {
    message: '',
  }

  handleLogout = async (e) => {
    e.preventDefault()

    const token = localStorage.getItem('@mywallet-token')
    const refreshToken = localStorage.getItem('@mywallet-refreshToken')

    if (!token || !refreshToken) {
      this.setState({ message: 'Não foi possível finalizar sua sessão.' })
    } else {
      logout()
      this.props.history.push('/')
    }
  }

  render() {
    const { name } = JSON.parse(localStorage.getItem('@mywallet-data'))

    return (
      <HeaderContainer>
        <HeaderContent>
          <img src={Logo} alt="My Wallet" />
          <ExitToApp
            className="btn-logout"
            aria-label="logout"
            onClick={this.handleLogout}
          />
        </HeaderContent>
        <UserContent>
          {this.state.message && <p>{this.state.message}</p>}
          <p>{name}</p>
        </UserContent>
      </HeaderContainer>
    )
  }
}

export default withRouter(Header)
