import axios from "axios";
import { getToken } from "./auth";

const baseURL = (process.env.NODE_ENV === 'production')
  ? process.env.REACT_APP_API_URI_PRD
  : process.env.REACT_APP_API_URI_DEV

const api = axios.create({
  baseURL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;