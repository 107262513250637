import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`

export const Form = styled.form`
  width: 350px;
  height: 100vh;
  padding: 2rem;
  //border: 1px solid var(--color-border-primary);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 192px;
    height: 192px;
    margin: 1rem auto 4rem;
  }

  p {
    color: var(--color-alert-error-text);
    margin-bottom: 15px;
    border: 1px solid var(--color-alert-error-border);
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  input {
    height: 46px;
    margin-bottom: 15px;
    padding: 0 20px;
    color: var(--color-text-white);
    font-size: 15px;
    width: 100%;
    background-color: var(--color-input-contrast-bg);
    border: 1px solid var(--color-input-border);
    transition: ease 0.4s;
    &::placeholder {
      color: var(--color-text-primary);
    }
  }

  input:focus {
    background-color: var(--color-bg-canvas-inverse);
    color: var(--color-text-inverse);
  }

  button {
    color: var(--color-text-inverse);
    font-size: 16px;
    background: var(--color-auto-yellow-8);
    height: 48px;
    border: 0;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    transition: ease 0.4s;
  }

  button:hover {
    background: var(--color-auto-yellow-5);
    color: var(--color-text-white);
  }

  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid var(--color-border-primary);
    width: 100%;
  }
`
