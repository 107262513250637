import React from "react";
import GlobalStyle from './styles/global'
import Routes from "./routes";
import { ToastContainer } from 'react-toastify';

const App = () => (
  <>
    <GlobalStyle />
    <ToastContainer />
    <Routes />
  </>
);
export default App;