import styled from 'styled-components'

export const Collapsible = styled.div`
  width: fit-content;
  min-width: 316px;
  max-width: 316px;
  max-height: 150px;

  position: relative;
  top: -10px;

  //border: 1px solid #fff;

  .header {
    color: var(--color-auto-yellow-8);
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .content {
    padding: 0 0.75rem;
    position: relative;
    top: -10px;
  }

  .hidden-fields {
    display: none;
  }

  .displayed-fields {
  }

  .displayed-fields > label {
    font-size: 0.9em;
  }

  .input-field {
    padding: 5px 4px;
    font-size: 0.9em;
    margin-right: 5px;
    border: 1px solid var(--color-border-primary);
    width: 200px;
  }

  .btn {
    padding: 5px 4px;
    font-size: 1em;
    width: 80px;
    border: 1px solid var(--color-alert-warn-border);
    background-color: var(--color-auto-yellow-8);
    color: var(--color-text-inverse);
    transition: all ease 0.5s;
    cursor: pointer;
  }

  .btn:hover {
    background-color: var(--color-auto-yellow-5);
    color: var(--color-text-white);
  }
`
