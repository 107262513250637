import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'

import { FooterContainer, FooterContent } from './styles'

import Logo from '../../assets/nftrend.svg'

class Footer extends Component {
  render() {
    return (
      <FooterContainer>
        <FooterContent>
          <div className="left">
            <a href="https://nftrend.com.br" target="_blank" rel="noreferrer">
              <ReactSVG src={Logo} className="nftd-logo" />
            </a>
          </div>
          <div className="middle">
            <small>2023 &copy; Todos os direitos reservados.</small>
          </div>
          <div className="right">
            <small>
              <a
                href="https://ticket.nftrend.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Solicitar suporte
              </a>
            </small>
            <small>
              <a
                href="https://galonaveia.atletico.com.br/portal-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Termos e condições
              </a>
            </small>
          </div>
        </FooterContent>
      </FooterContainer>
    )
  }
}

export default Footer
