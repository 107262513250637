import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import Header from '../../components/Header'
import {
  MainContainer,
  MainContent,
  NftCardWrapper,
} from '../../styles/components/Main'
import NftCard from '../../components/NftCard'
import Footer from '../../components/Footer'

import api from '../../services/api'

export default function UserFeed() {
  const { access_times } = JSON.parse(localStorage.getItem('@mywallet-data'))
  const [userNfts, setUserNfts] = useState([])

  useEffect(() => {
    const { user_id } = JSON.parse(localStorage.getItem('@mywallet-data'))
    const token = localStorage.getItem('@mywallet-token')

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { user_id },
    }

    api.get('/users-nfts', config).then((response) => {
      setUserNfts(response.data)
    })
  }, [])

  if (access_times === 0) {
    return <Redirect push to="/primeiro-acesso" />
  } else {
    return (
      <>
        <Header />
        <MainContainer>
          <MainContent>
            {userNfts.map((nft) => (
              <NftCardWrapper key={nft.id}>
                <NftCard nft={nft} />
              </NftCardWrapper>
            ))}
          </MainContent>
        </MainContainer>
        <Footer />
      </>
    )
  }
}
