import React, { useState, useEffect, useRef } from "react";
import useCollapse from "react-collapsed";
import { Collapsible } from "./styles";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function NftTransfer(props) {
  const useCollapseConfig = {
    duration: 1000,
    easing: "cubic-bezier(.9,.25,.5,.25)",
    defaultExpanded: false,
  };

  const [isExpanded, setExpanded] = useState(useCollapseConfig.defaultExpanded);
  const { getCollapseProps, getToggleProps } = useCollapse(useCollapseConfig);

  function handleCollapseClick() {
    setExpanded(!isExpanded);
  }

  const { user_id, name, email } = JSON.parse(
    localStorage.getItem("@mywallet-data")
  );
  const form = useRef();

  const [formInput, setFormInput] = useState({
    input_wallet_id: "Território Alvinegro - Carteira de Ativos Digitais",
    input_name: "",
    input_user_id: "",
    input_email: "",
    input_nft_name: "",
    input_asset_contract: "",
    input_token_id: "",
    input_hot_wallet_id: "",
  });

  useEffect(() => {
    setFormInput({
      input_wallet_id: "Território Alvinegro - Carteira de Ativos Digitais",
      input_name: name,
      input_user_id: user_id,
      input_email: email,
      input_nft_name: props.nft.name,
      input_asset_contract: props.nft.asset_contract,
      input_token_id: props.nft.token_id,
      input_hot_wallet_id: "",
    });
  }, [props, name, user_id, email]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormInput({
      ...formInput,
      [e.target.name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    console.log(form.current);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.info(result.text);
          toast.success("Solicitação enviada.");
          setFormInput({
            ...formInput,
            input_hot_wallet_id: "",
          });
        },
        (error) => {
          console.error(error.text);
          toast.error("Erro ao enviar sua solicitação.");
          setFormInput({
            ...formInput,
            input_hot_wallet_id: "",
          });
        }
      );
  };

  return (
    <>
      <Collapsible>
        <div
          className="header"
          {...getToggleProps({ onClick: handleCollapseClick })}
        >
          <div className="title">Transferência de NFT</div>
          <div className="icon">
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
        </div>
        <div className="content" {...getCollapseProps()}>
          <form ref={form} onSubmit={sendEmail}>
            <div className="hidden-fields">
              <input
                type="text"
                name="input_wallet_id"
                value={formInput.input_wallet_id}
                placeholder={formInput.input_wallet_id}
                onChange={handleInputChange}
                readOnly
              />
              <input
                type="text"
                name="input_name"
                value={formInput.input_name}
                placeholder={formInput.input_name}
                onChange={handleInputChange}
                readOnly
              />
              <input
                type="text"
                name="input_user_id"
                value={formInput.input_user_id}
                placeholder={formInput.input_user_id}
                onChange={handleInputChange}
                readOnly
              />
              <input
                type="text"
                name="input_email"
                value={formInput.input_email}
                placeholder={formInput.input_email}
                onChange={handleInputChange}
                readOnly
              />
              <input
                type="text"
                name="input_nft_name"
                value={formInput.input_nft_name}
                placeholder={formInput.input_nft_name}
                onChange={handleInputChange}
                readOnly
              />
              <input
                type="text"
                name="input_asset_contract"
                value={formInput.input_asset_contract}
                placeholder={formInput.input_asset_contract}
                onChange={handleInputChange}
                readOnly
              />
              <input
                type="text"
                name="input_token_id"
                value={formInput.input_token_id}
                placeholder={formInput.input_token_id}
                onChange={handleInputChange}
                readOnly
              />
            </div>
            <div className="displayed-fields">
              <label htmlFor="input_hot_wallet_id">
                Insira o código hash da sua Metamask
              </label>
              <input
                className="input-field"
                type="text"
                name="input_hot_wallet_id"
                placeholder="Metamask Hash"
                value={formInput.input_hot_wallet_id}
                onChange={handleInputChange}
                required
              />
              <input className="btn" type="submit" value="Solicitar" />
            </div>
          </form>
        </div>
      </Collapsible>
    </>
  );
}
