import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100vw;

  @media only screen and (min-height: 600px) and (orientation: portrait) {
    height: 40vh !important;
  }

  @media only screen and (min-height: 700px) and (orientation: portrait) {
    height: 35vh !important;
  }

  @media only screen and (min-height: 800px) and (orientation: portrait) {
    height: 30vh !important;
  }

  @media only screen and (min-height: 896px) and (orientation: portrait) {
    height: 25vh !important;
  }

  @media (min-width: 600px) {
    height: auto !important;
  }

  position: relative;
  bottom: 0;
  margin: 0 auto 1rem;
  padding: 0 2rem;

  overflow: hidden;
`

export const FooterContent = styled.div`
  display: grid;
  gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  border: none;
  border-top: 1px solid var(--color-border-primary);
  width: 100%;

  margin: 0 auto;
  padding: 1rem 2rem;

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .nftd-logo svg {
      width: 125px;
      height: 40px;
    }
    .nftd-logo svg img {
      width: 100px;
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 2;
  }

  a {
    font-size: 1em;
    font-weight: 600;
    color: var(--color-scale-yellow-1);
    text-decoration: none;
    transition: ease 0.4s;
  }

  a:hover {
    color: var(--color-scale-yellow-3);
  }
`
