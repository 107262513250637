import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;

  background-color: var(--color-bg-tertiary);
`

export const HeaderContent = styled.div`
  display: flex;
  flex-wrap: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 80vw;
  height: 115px;

  img {
    width: 96px;
  }

  .btn-logout {
    cursor: pointer;
    font-size: 1.85em;
    color: var(--color-auto-yellow-8);
    transition: ease 0.4s;
  }

  .btn-logout:hover {
    color: var(--color-auto-yellow-5);
  }
`

export const UserContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 80vw;
  margin-bottom: 15px;
`
