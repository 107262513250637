import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Logo from '../../assets/logo_192.png'
import api from '../../services/api'
import { logout } from '../../services/auth'

import { Form, Container } from './styles'

import Footer from '../../components/Footer'

class FirstAccess extends Component {
  state = {
    currentPassword: '',
    newPassword: '',
    confirmedPassword: '',
    error: '',
  }

  handlePasswordUpdate = async (e) => {
    e.preventDefault()

    const { currentPassword, newPassword, confirmedPassword } = this.state

    const { user_id } = JSON.parse(localStorage.getItem('@mywallet-data'))
    const token = localStorage.getItem('@mywallet-token')

    if (!currentPassword || !newPassword || !confirmedPassword) {
      this.setState({
        error: 'Informe os dados solicitados em todos os campos.',
      })
    } else if (newPassword !== confirmedPassword) {
      this.setState({
        error: 'A nova senha não confere com a de confirmação.',
      })
    } else {
      try {
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          params: { userID: user_id, password: newPassword },
        }

        const response = await api.put('/users', config)

        if (response.data === 1) {
          logout()
          this.props.history.push('/')
        } else {
          this.setState({
            error:
              'Houve um problema ao tentar atualizar a senha. Por favor, encaminhe uma solicitação de ajuda para contato@nftrend.com.br',
          })
        }
      } catch (err) {
        this.setState({
          error:
            'Houve um problema ao tentar atualizar a senha. Por favor, encaminhe uma solicitação de ajuda para contato@nftrend.com.br',
        })
      }
    }
  }

  render() {
    return (
      <Container>
        <Form onSubmit={this.handlePasswordUpdate}>
          <img src={Logo} alt="MyWallet" />
          <h2>Primeiro acesso</h2>
          <p>
            Sua primeira vez aqui, certo? Neste caso, pedimos que atualize a
            senha cadastrada por nós.
          </p>

          {this.state.error && <p className="error">{this.state.error}</p>}
          <input
            type="password"
            placeholder="Senha atual"
            onChange={(e) => this.setState({ currentPassword: e.target.value })}
          />
          <input
            type="password"
            placeholder="Nova senha"
            onChange={(e) => this.setState({ newPassword: e.target.value })}
          />
          <input
            type="password"
            placeholder="Confirmar nova senha"
            onChange={(e) =>
              this.setState({ confirmedPassword: e.target.value })
            }
          />
          <button type="submit">Atualizar senha</button>
        </Form>

        <Footer />
      </Container>
    )
  }
}

export default withRouter(FirstAccess)
